import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //src: 'https://open1999.thinker.tw',
    //subDirectory: '/Platform',
    //src: 'https://localhost:44320',
    //subDirectory: ''
    src: 'https://1999.tainan.gov.tw',
    subDirectory: '/Platform',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
