<template>
  <div>
    <Head />

    <!-- 會員 個人通報案件狀況 -->
    <div class="wrapper member" v-if="MemberInfoData">
      <a href="#" class="sr-only sr-only-focusable" id="AC" accesskey="C" name="C" title="主要內容"><img src="img/icon-circle.svg" alt=""></a>
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" title="首頁">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                會員案件紀錄
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <!-- 左側邊會員按鈕 -->
          <ul class="member-btn">
            <li>
              <router-link to="/MemberCase" tag="button" title="案件紀錄">
                <img src="/img/member-case.svg" alt="" />
                案件紀錄
              </router-link>
            </li>
            <li>
              <router-link to="/MemberInfo" tag="button" class="active" title="會員資料">
                <img src="/img/member-information.svg" alt="" />
                會員資料
              </router-link>
            </li>
          </ul>
          <!-- 1019 -->
          <!-- 右邊框框 -->
          <div class="box" style="display: block">
            <h1>個人資料</h1>
            <div class="information">
              <div class="form-group">
                <label for="">真實姓名</label>
                <input
                  class="form-control"
                  :value="MemberInfoData.User.Name"
                  readonly
                />
              </div>
              <div class="form-group">
                <label for="">帳　號</label>
                <input
                  class="form-control"
                  :value="MemberInfoData.User.Account"
                  readonly
                />
              </div>
              <hr />
              <div
                class="form-group"
                v-if="pincodeType == -1 || pincodeType == 2"
              >
                <label for="">手機號碼</label>
                <input
                  class="form-control"
                  :value="MemberInfoData.User.Phone"
                  readonly
                />
              </div>
              <div
                class="form-group"
                v-if="pincodeType == -1 || pincodeType == 2"
              >
                <label for="">新手機號碼</label>
                <div>
                  <input class="form-control" v-model="NewPhone" />
                  <button @click="ChangePhone()">取得檢核碼</button>
                </div>
              </div>
              <hr v-if="pincodeType == -1" />
              <div
                class="form-group"
                v-if="pincodeType == -1 || pincodeType == 3"
              >
                <label for="">電子信箱</label>
                <input
                  class="form-control"
                  :value="MemberInfoData.User.Email"
                  readonly
                />
              </div>
              <div
                class="form-group"
                v-if="pincodeType == -1 || pincodeType == 3"
              >
                <label for="">新電子信箱</label>
                <div>
                  <input class="form-control" v-model="NewEmail" />
                  <button @click="ChangeEmail()">取得檢核碼</button>
                </div>
              </div>
              <hr v-if="pincodeType == -1" />
              <div class="form-group" v-if="pincodeType != -1 && pincode != ''">
                <label for="">個資檢核碼</label>
                <div>
                  <input
                    class="form-control"
                    v-model="pincode_input"
                    @input="pincode_input_onInput"
                  />
                  <button v-if="pincodeCheck" @click="SendChange()">
                    送出
                  </button>
                </div>
              </div>
              <div class="form-group" v-if="pincodeType == -1">
                <label for="">舊密碼</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="請輸入舊密碼"
                  v-model="Password"
                />
              </div>
              <div class="form-group" v-if="pincodeType == -1">
                <label for="">新密碼</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="請輸入新密碼"
                  v-model="NewPassword"
                />
              </div>
              <div class="form-group" v-if="pincodeType == -1">
                <label for="">確認新密碼</label>
                <div>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="請再次輸入新密碼"
                    v-model="NewPasswordConfirm"
                  />
                  <button @click="ChangePassword()">修改密碼</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "MemberInfo",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      MemberInfoData: null,
      NewPhone: "",
      NewEmail: "",
      Password: "",
      NewPassword: "",
      NewPasswordConfirm: "",
      pincodeType: -1,
      pincodeNewPhone: "",
      pincodeNewEmail: "",
      issned: false,
      pincode_input: "",
      pincode: "",
      pincodeCheck: false,
      pincodeTime: 0,
    };
  },
  watch: {
    NewPhone(newVal, oldVal) {
      if (newVal != this.pincodeNewPhone) {
        this.pincode = "";
        this.pincodeCheck = false;
      }
    },
    NewEmail(newVal, oldVal) {
      if (newVal != this.pincodeNewEmail) {
        this.pincode = "";
        this.pincodeCheck = false;
      }
    },
  },
  beforeCreate: function () {
    if (!$cookies.isKey("open_login_token")) {
      this.$router.push({
        name: "home",
      });
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/MemberInfo",
        {
          params: {
            token: $cookies.get("open_login_token"),
          },
        }
      )
      .then((response) => {
        this.MemberInfoData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {    
    document.title = 'OPEN1999 - 會員資料';
  },
  updated: function () {},
  methods: {
    pincode_input_onInput() {
      if (this.pincode_input != "" && this.pincode_input == this.pincode) {
        this.pincodeCheck = true;
      } else {
        this.pincodeCheck = false;
      }
    },
    ChangePhone() {
      if (this.pincodeTime > 0) {
        Swal.fire({
          title: "請稍候",
          html: "重新獲取驗證碼需等待 <span>" + this.pincodeTime + "</span> 秒",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.MemberInfoData.User.Phone == this.NewPhone) {
        Swal.fire({
          title: "錯誤",
          text: "請輸入不同的手機號碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (!Validate.reg_phone(this.NewPhone)) {
        Swal.fire({
          title: "錯誤",
          text: "新手機號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 組合並送出
      let token = AES.encrypt("2|" + this.NewPhone + "|");
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/GetCheckNumber",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            that.pincodeType = 2;
            that.issned = true;
            var strInfo = AES.decrypt(res.PinCode).split("|");
            that.pincode = strInfo[0];
            that.pincodeNewPhone = strInfo[2];
            // 倒數
            that.pincodeTime = 60;
            var loop = window.setInterval(function () {
              that.pincodeTime -= 1;
              if (Swal.getHtmlContainer()) {
                const span = Swal.getHtmlContainer().querySelector("span");
                if (span) {
                  span.textContent = that.pincodeTime;
                }
                if (that.pincodeTime == 0) {
                  window.clearInterval(loop);
                }
              }
            }, 1000);
            Swal.fire({
              title: "已發送驗證碼",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            document.body.scrollTop = 0;
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    ChangeEmail() {
      if (this.pincodeTime > 0) {
        Swal.fire({
          title: "請稍候",
          html: "重新獲取驗證碼需等待 <span>" + this.pincodeTime + "</span> 秒",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.MemberInfoData.User.Email == this.NewEmail) {
        Swal.fire({
          title: "錯誤",
          text: "請輸入不同的電子信箱",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (!Validate.reg_email(this.NewEmail)) {
        Swal.fire({
          title: "錯誤",
          text: "新電子信箱格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 組合並送出
      let token = AES.encrypt("3||" + this.NewEmail);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/GetCheckNumber",
        type: "post",
        data: {
          token: token,
          checkRepeat: true,
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            that.pincodeType = 3;
            that.issned = true;
            var strInfo = AES.decrypt(res.PinCode).split("|");
            that.pincode = strInfo[0];
            that.pincodeNewEmail = strInfo[3];
            // 倒數
            that.pincodeTime = 60;
            var loop = window.setInterval(function () {
              that.pincodeTime -= 1;
              if (Swal.getHtmlContainer()) {
                const span = Swal.getHtmlContainer().querySelector("span");
                if (span) {
                  span.textContent = that.pincodeTime;
                }
                if (that.pincodeTime == 0) {
                  window.clearInterval(loop);
                }
              }
            }, 1000);
            Swal.fire({
              title: "已發送驗證碼",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            document.body.scrollTop = 0;
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    ChangePassword() {
      if (this.NewPassword != this.NewPasswordConfirm) {
        Swal.fire({
          title: "錯誤",
          text: "新密碼與確認新密碼不符合, 請確認後再試一次",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      let token = AES.encrypt(
        this.MemberInfoData.User.Account +
          "|" +
          this.Password +
          "|" +
          this.NewPasswordConfirm
      );
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ChangePassword",
        type: "post",
        data: {
          token: token,
          checkRepeat: true,
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "修改完成",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                // 重整頁面
                location.reload();
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    SendChange() {
      let token = AES.encrypt(
        this.MemberInfoData.User.Account +
          "|" +
          this.pincodeType +
          "|" +
          this.NewPhone +
          "|" +
          this.NewEmail
      );
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ChangeInfo",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            if (this.pincodeType == 2) {
              Swal.fire({
                title: "修改完成",
                icon: "success",
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500,
                willClose: () => {
                  // 重整頁面
                  location.reload();
                },
              });
            } else {
              $cookies.remove("open_login_token");
              Swal.fire({
                title: "修改完成",
                text: "請使用新的電子信箱進行登入",
                icon: "success",
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                timer: 3000,
                willClose: () => {
                  that.$router.push({
                    name: "home",
                  });
                },
              });
            }
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
  },
};
</script>

