<template>
  <div>
    <Head />

    <!-- 列表 -->
    <div class="wrapper list" v-if="CategoryData && CategoryData.Category">
      <a href="#" class="sr-only sr-only-focusable" id="AC" accesskey="C" name="C" title="主要內容"><img src="img/icon-circle.svg" alt=""></a>
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" title="首頁">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ CategoryData.Category.CategoryName }}
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <h1>{{ CategoryData.Category.CategoryName }}</h1>
          <ul class="list-box">
            <li v-for="Post in Posts" :key="Post.PId">
              <a href="javascript:void(0);" @click="gotoPost(Post)">
                <p>{{ Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
                <p>
                  {{ Post.Title }}
                </p>
              </a>
            </li>
          </ul>
          <!-- 頁碼 -->
          <section class="page" v-if="pageAll > 1">
            <paginate
              :no-li-surround="false"
              :page-count="pageAll"
              :page-range="3"
              :margin-pages="1"
              :click-handler="loadList"
              :prev-text="'<'"
              :next-text="'>'"
              :prev-link-class="''"
              :next-link-class="''"
              :container-class="'pagination'"
              :page-link-class="''"
              :active-class="'active'"
              :active-link-class="'active'"
              :hide-prev-next="false"
            >
            </paginate>
          </section>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

export default {
  name: "Category",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      CategoryData: null,
      Posts: null,
      pageAll: 0,
      perPage: 10,
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/Category",
        {
          params: { CId: this.$route.params.CId },
        }
      )
      .then((response) => {
        this.CategoryData = response.data;
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    document.title = 'OPEN1999 - 文章列表';
  },
  updated: function () {},
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/PostList",
        type: "post",
        data: {
          CId: this.$route.params.CId,
          page: page,
          perPage: that.perPage,
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          if (res.status == 1) {
            that.Posts = res.Posts;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Blank) {
          window.open(Post.Url, "_blank");
        } else {
          location.href = Post.Url;
        }
      } else {
        this.$router.push({
          name: "post",
          params: { PId: Post.PId },
        });
      }
    },
  },
};
</script>

