import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CaseSearch from '../views/CaseSearch.vue'
import CaseDetail from '../views/CaseDetail.vue'
import CaseAdd from '../views/CaseAdd.vue'
import Register from '../views/Register.vue'
import Forget from '../views/Forget.vue'
import API from '../views/API.vue'
import SiteMap from '../views/SiteMap.vue'
import FAQ from '../views/FAQ.vue'
import MemberCase from '../views/MemberCase.vue'
import Category from '../views/Category.vue'
import Post from '../views/Post.vue'
import MapSearch from '../views/MapSearch.vue'
import MemberInfo from '../views/MemberInfo.vue'
import CaseStatistics from '../views/CaseStatistics.vue'
//import Form from '../views/Form.vue'
//import OldRegister from '../views/OldRegister.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/CaseSearch',
    name: 'caseSearch',
    component: CaseSearch
  },
  {
    path: '/CaseDetail',
    name: 'caseDetail',
    component: CaseDetail
  },
  {
    path: '/CaseAdd/:CTIId',
    name: 'caseAdd',
    component: CaseAdd
  },
  {
    path: '/Register',
    name: 'register',
    component: Register
  },
  {
    path: '/Forget',
    name: 'forget',
    component: Forget
  },
  {
    path: '/API',
    name: 'api',
    component: API
  },
  {
    path: '/SiteMap',
    name: 'siteMap',
    component: SiteMap
  },
  {
    path: '/FAQ',
    name: 'faq',
    component: FAQ
  },
  {
    path: '/MemberCase',
    name: 'memberCase',
    component: MemberCase
  },
  {
    path: '/Category/:CId',
    name: 'category',
    component: Category
  },
  {
    path: '/Post/:PId',
    name: 'post',
    component: Post
  },
  {
    path: '/MapSearch',
    name: 'mapSearch',
    component: MapSearch
  },
  {
    path: '/MemberInfo',
    name: 'memberInfo',
    component: MemberInfo
  },
  {
    path: '/CaseStatistics',
    name: 'caseStatistics',
    component: CaseStatistics
  },
  // {
  //   path: '/Form',
  //   name: 'form',
  //   component: Form
  // },
  // {
  //   path: '/OldRegister',
  //   name: 'oldRegister',
  //   component: OldRegister
  // },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
