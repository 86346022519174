<template>
  <div>
    <Head />

    <div class="wrapper member case-search" v-if="CaseSearchData">
      <a href="#" class="sr-only sr-only-focusable" id="AC" accesskey="C" name="C" title="主要內容"><img src="img/icon-circle.svg" alt=""></a>
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" title="首頁">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                近期案件
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <div class="box" style="display: block">
            <div class="title-box">
              <!-- 1124 新增div和img -->
              <div>
                <img class="h1-img" src="img/h1-search.svg" alt="">
                <h1>近期案件</h1>
              </div>
              <!-- 20241212 a title -->
              <router-link to="/MapSearch" title="熱點地圖查詢">
                <p>熱點地圖查詢</p>
              </router-link>
            </div>
            <!-- 搜尋欄 -->
            <div class="searth-box">
              <div class="searth">
                <div class="form-group">
                  <select class="form-control" id="Subj_District" v-model="Subj_District">
                    <option value="">行政區</option>
                    <option v-for="(Area, index) in CaseSearchData.Areas" :key="index" :text="Area.Name"
                      :value="Area.AreaId">
                      {{ Area.Name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <select class="form-control" v-model="CTIId">
                    <option value="-1">全部</option>
                    <option v-for="(CaseType, index) in CaseSearchData.CaseTypes" :key="index" :value="CaseType.CTIId">
                      {{ CaseType.Title }}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <p>公布最新50件通報案件資料</p>
              </div>
            </div>
            <!-- 案件分類按鈕 -->
            <ul class="btn-box">
              <li>
                <button :class="Status == '' ? 'active' : ''" @click="Status = ''">
                  全部
                </button>
              </li>
              <li>
                <button :class="Status == '3' ? 'active' : ''" @click="Status = '3'">
                  處理中
                </button>
              </li>
              <li>
                <button :class="Status == '4' ? 'active' : ''" @click="Status = '4'">
                  結案
                </button>
              </li>
            </ul>
            <!-- 全部案件1 -->
            <ul class="case-box show">
              <!-- 第一個 -->
              <!-- 換處理中的顏色 class="processing" -->
              <li v-for="Case in Cases" :key="Case.CaseId" :class="Case.Status == 3 ? 'processing' : ''">
                <!-- 大img -->
                <div class="circle">
                  <div>
                    <img src="/img/case-progress1.svg" alt="通報中" v-if="Case.Status != 3 && Case.Status != 4" />
                    <img src="/img/case-progress3.svg" alt="處理中" v-if="Case.Status == 3" />
                    <img src="/img/case-progress5.svg" alt="已結案" v-if="Case.Status == 4" />
                  </div>
                </div>
                <!-- 標題 -->
                <div class="title">
                  <h2 v-if="Case.Status != 3 && Case.Status != 4">已通報</h2>
                  <h2 v-if="Case.Status == 3">處理中</h2>
                  <h2 v-if="Case.Status == 4">已結案</h2>
                  <p v-if="Case.Status != 4">
                    立案日期：{{ Case.CreateTime | timeString("YYYY-MM-DD") }}
                  </p>
                  <p v-if="Case.Status == 4">{{ Case.FinishTime }}</p>
                </div>
                <!-- 文字 -->
                <div class="text-box">
                  <div>
                    <p>行政區</p>
                    ：
                    <p>{{ Case.Subj_District_Text }}</p>
                  </div>
                  <div>
                    <p>地點</p>
                    ：
                    <p>{{ Case.Subj_Location }}</p>
                  </div>
                  <div>
                    <p>類型</p>
                    ：
                    <p>{{ Case.Subj_Item_Text }}</p>
                  </div>
                  <div v-if="Case.Subj_Subitem_Text">
                    <p>事項</p>
                    ：
                    <p>{{ Case.Subj_Subitem_Text }}</p>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 頁碼 -->
            <section class="page" v-if="pageAll > 1">
              <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                :click-handler="loadList" :prev-text="'<'" :next-text="'>'" :prev-link-class="''" :next-link-class="''"
                :container-class="'pagination'" :page-link-class="''" :active-class="'active'"
                :active-link-class="'active'" :hide-prev-next="false">
              </paginate>
            </section>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Paginate from "vuejs-paginate";

export default {
  name: "CaseSearch",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      CaseSearchData: null,
      Status: "",
      Subj_District: "",
      CTIId: -1,
      Cases: null,
      pageAll: 0,
      perPage: 5,
    };
  },
  watch: {
    Status(newVal, oldVal) {
      this.loadList(1);
    },
    Subj_District(newVal, oldVal) {
      this.loadList(1);
    },
    CTIId(newVal, oldVal) {
      this.loadList(1);
    },
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/CaseSearch",
        {
          params: {},
        }
      )
      .then((response) => {
        this.CaseSearchData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    document.title = 'OPEN1999 - 近期案件';
    this.loadList(1);
  },
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/CaseSearchList",
        type: "post",
        data: {
          Status: that.Status,
          Subj_District: that.Subj_District,
          CTIId: that.CTIId,
          page: page,
          perPage: that.perPage,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.status == 1) {
            that.Cases = res.Cases;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
  },
};
</script>
